<template>
  <div class="coming-soon relative flex-col items-around">
    <div class="w-full border-red-700 z-50">
      <div class="w-full h-[40vh] sm:block sm:h-[70vh]">
        <spline-viewer
          class="custom-spline-viewer"
          url="https://prod.spline.design/dOPFCitodniuV3rA/scene.splinecode"
        ></spline-viewer>
      </div>
    </div>
    <div class="content">
      <!-- <h1 class="title">Coming Soon</h1>
        <p class="subtitle">Our creative space is under construction</p> -->
      <div class="countdown">
        <div class="countdown-item">
          <span class="countdown-value">{{ countdown.days }}</span>
          <span class="countdown-label">Days</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value">{{ countdown.hours }}</span>
          <span class="countdown-label">Hours</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value">{{ countdown.minutes }}</span>
          <span class="countdown-label">Minutes</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value">{{ countdown.seconds }}</span>
          <span class="countdown-label">Seconds</span>
        </div>
      </div>
    </div>
    <div class="background">
      <div class="shape shape1"></div>
      <div class="shape shape2"></div>
      <div class="shape shape3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModernComingSoon",
  data() {
    return {
      email: "",
      countdown: {
        days: 30,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      launchDate: new Date("2024-10-01T00:00:00"),
    };
  },
  methods: {
    subscribeNewsletter() {
      // Implement newsletter subscription logic here
      console.log("Subscribed:", this.email);
      this.email = "";
      alert("Thanks for subscribing!");
    },
    updateCountdown() {
      const now = new Date();
      const difference = this.launchDate - now;

      this.countdown.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      this.countdown.minutes = Math.floor((difference / 1000 / 60) % 60);
      this.countdown.seconds = Math.floor((difference / 1000) % 60);
    },
    removeSplineViewerLogo() {
      // Wait for the DOM to load
      this.$nextTick(() => {
        // Select the spline-viewer element
        const splineViewer = this.$el.querySelector("spline-viewer");

        if (splineViewer) {
          console.log(splineViewer);
          // Access the shadow root
          const shadowRoot = splineViewer.shadowRoot;
          console.log(shadowRoot);

          if (shadowRoot) {
            // Find the logo element inside the shadow DOM
            const logoElement = shadowRoot.querySelector('a[id="logo"]');
            console.log(logoElement);

            if (logoElement) {
              logoElement.style.visibility = "hidden"; // Hide the logo
              // or
              // logoElement.remove(); // Completely remove the logo element
            } else {
              console.error("Logo element not found in shadow DOM");
            }
          } else {
            console.error("Shadow root not found");
          }
        } else {
          console.error("spline-viewer element not found");
        }
      });
    },
  },

  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
    this.removeSplineViewerLogo();
  },
};
</script>

<style scoped>
.coming-soon {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.content {
  text-align: center;
  z-index: 10;
  padding: 2rem;
}

.title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  animation: fadeInUp 1s ease-out;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out 0.5s both;
}

.countdown {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.countdown-item {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 1rem;
  border-radius: 8px;
  min-width: 80px;
}

.countdown-value {
  font-size: 2rem;
  font-weight: bold;
  display: block;
}

.countdown-label {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  animation: fadeInUp 1s ease-out 1s both;
}

input[type="email"] {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  width: 250px;
}

button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: float 20s infinite;
}

.shape1 {
  width: 400px;
  height: 400px;
  top: -200px;
  right: -100px;
}

.shape2 {
  width: 300px;
  height: 300px;
  bottom: -150px;
  left: -50px;
  animation-delay: -5s;
}

.shape3 {
  width: 200px;
  height: 200px;
  bottom: 50%;
  right: 30%;
  animation-delay: -10s;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(10deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 3rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .countdown {
    flex-wrap: wrap;
  }
  .newsletter-form {
    flex-direction: column;
    align-items: center;
  }
  input[type="email"],
  button {
    width: 100%;
    max-width: 300px;
  }
}
</style>
